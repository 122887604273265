import { useMutation } from "@vue/apollo-composable";

import type { OperationVariables, TypedDocumentNode } from "@apollo/client/core";
import type { UseMutationReturn } from "@vue/apollo-composable";
import type { ReactiveFunction } from "@vue/apollo-composable/dist/util/ReactiveFunction.js";
import type { DocumentNode } from "graphql/index";

const useGraphqlMutation = <TResult, TVariables extends OperationVariables>(
  document:
    | DocumentNode
    | ReactiveFunction<DocumentNode>
    | ReactiveFunction<TypedDocumentNode<TResult, TVariables>>
    | Ref<DocumentNode>
    | Ref<TypedDocumentNode<TResult, TVariables>>
    | TypedDocumentNode<TResult, TVariables>,
  options?: GraphqlMutationOptions<TResult, TVariables>,
): UseMutationReturn<TResult, TVariables> => {
  return useMutation(document, options);
};

export { useGraphqlMutation };
